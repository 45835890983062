:root {
  --black: #191919;
  --secondary: #898798;
  --primary: #00034a;
}

*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0px solid slategrey;
}

.black {
  color: var(--black) !important;
}
.secondary {
  color: var(--secondary) !important;
}
.primary {
  color: var(--primary) !important;
}

.bg-main-black {
  background-color: var(--black) !important;
}
.bg-main-secondary {
  background-color: var(--secondary) !important;
}
.bg-main-primary {
  background-color: var(--primary) !important;
}

.navbar-toggle {
  border: none !important;
}
.navbar-toggle:focus {
  box-shadow: none !important;
}
.nav-link {
  color: #191919 !important;
}
.nav-link.active {
  text-decoration: underline solid var(--black) 2px;
  text-underline-position: under;
}

.btn-main {
  color: #fff;
  background-color: #494fff;
  border-color: transparent;
}

.btn-main.disabled,
.btn-main:disabled {
  color: #fff;
  background-color: #9698ce;
  border-color: transparent;
}

.btn-main:not(:disabled):not(.disabled):active,
.btn-main:not(:disabled):not(.disabled).active,
.show > .btn-main.dropdown-toggle {
  color: #fff;
  background-color: rgb(0, 6, 179);
  border-color: rgb(85, 64, 221);
}

.btn-main:not(:disabled):not(.disabled):active:focus,
.btn-main:not(:disabled):not(.disabled).active:focus,
.show > .btn-main.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-main.focus,
.btn-main:focus {
  color: #fff;
  background-color: #5f65fe;
  border-color: transparent;
}
.btn-main:hover {
  color: #fff;
  background-color: #9698ce;
  border-color: transparent;
  box-shadow: none;
}

.w-max {
  width: max-content !important;
}

.mt-section {
  margin-top: 10rem !important;
}

.mt-nav {
  margin-top: 130px;
}

.navbar {
  transition: all 0.2s;
}

.hero-section {
  border-radius: 30px;
  background-color: #f4f5ff;
}
.hero-title {
  padding-left: 3rem !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.hero-img {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 3rem !important;
}

@media (max-width: 991px) {
  .hero-img {
    order: 1 !important;
    padding: 0 !important;
    padding-bottom: 3rem !important;
  }
  .hero-img > img {
    width: 100% !important;
  }
  .hero-title {
    padding-left: 0 !important;
    order: 2 !important;
  }
}

.layanan {
  padding: 2.5rem 1.5rem !important;
  border-radius: 30px;
  background-color: #f4f5ff;
}

.layanan p {
  font-size: 15px;
}

.fs-normal {
  font-size: 15px !important;
}

.gap-main {
  gap: 2.5rem !important;
}
.gap-sub {
  gap: 2rem !important;
}

.keunggulan {
  padding: 2.5rem 1.5rem !important;
  border-radius: 30px;
  background-color: #f4f5ff;
  justify-content: center !important;
}

.login-area {
  position: absolute;
  background-color: #f4f5ff;
  border-radius: 8px;
  top: 23%;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-control:focus {
  border-color: #e1e1e1 !important;
  box-shadow: 0 0 0 0.25rem rgba(68, 68, 68, 0.25) !important;
}

.password-icon {
  float: right;
  right: 12px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fw-regular {
  font-weight: 500 !important;
}

.admin-hero {
  position: relative;
  object-fit: cover;
  min-height: 15rem;
  height: 15rem;
}

.header-title {
  z-index: 10;
  position: absolute;
  top: 5rem;
  color: #ffff;
  text-align: center;
  background-color: transparent;
}

.bg-transparent {
  background-color: transparent !important;
}
.accordion-button {
  background-color: #00024adc !important;
  color: #ffff !important;
  font-weight: 600;
}

.bg-faq {
  background-color: #050bbb10;
}

.accordion-button:focus {
  border-color: #050bbbc3 !important;
  box-shadow: 0 0 0 0.25rem #050bbb7c !important;
}

.accordion-button::after {
  background-image: url("../images/Down.svg") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/Down.svg") !important;
}

.rdt_TableHeadRow {
  background-color: #333462 !important;
  color: #ffff;
  font-weight: 700;
  font-size: 14px;
}

.btn-success:hover {
  background-color: #7be39f !important;
}
.btn-success:focus {
  background-color: rgb(53, 190, 101) !important;
}

#hero-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
